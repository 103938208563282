import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { Module, modules } from "../../lib/data/modules";
import { LaserConfig } from "../../lib/models/Plan";
import FastForm from "../FastForm/FastForm";
import FastInput from "../FastForm/FastInput";
import Flex from "../Flex";

type Props = {
  className?: string;
  group: GroupWithPlan;
  onSubmit: (laserCodes: LaserConfig) => Promise<any>;
};

function LaserCodeInput({ className, group, onSubmit }: Props) {
  const [submittable, setSubmittable] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  const type = _.first(group.units).type;

  const mod: Module = modules[type];

  if (!mod) {
    return (
      <Alert severity="warning">
        Module not found for type {type}. This module may not be supported by
        Frag Orders
      </Alert>
    );
  }

  if (!mod.laserConfigFields) {
    return (
      <Box marginTop={2}>
        <Alert severity="warning">
          Module {mod.label} does not have laser configuration fields.
        </Alert>
      </Box>
    );
  }

  return (
    <div className={className}>
      <FastForm
        onSubmit={(vals) => {
          setSubmitting(true);
          const cfg: LaserConfig = [];

          _.each(vals, (v, k) => {
            const [, num, field] = k.split(".");

            if (!cfg[num]) {
              cfg[num] = {};
            }

            cfg[num][field] = v;
          });

          return onSubmit(cfg).finally(() => {
            setSubmitting(false);
          });
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unit</TableCell>
              {_.map(mod.laserConfigFields, (num, k) => (
                <TableCell key={k}>{k}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(group.units, (u, i) => (
              <TableRow key={u.name}>
                <TableCell width={36} key="name">
                  {group.name}-{i + 1}
                </TableCell>
                {_.map(mod.laserConfigFields, (v, k) => {
                  const existing = _.get(group, ["plan", "laserConfig", i]);

                  let val = _.toString(_.get(existing, v.fieldName));

                  if (!val) {
                    val = v.formatter(u);
                  }

                  return (
                    <TableCell style={{ width: 24 }} key={k}>
                      <Flex align>
                        {/* <div>
                          <Typography fontWeight={600} fontSize={16}>
                            1
                          </Typography>
                        </div> */}
                        <FastInput
                          validator={(val: string) => {
                            // Only allow exactly 3 digits
                            const t = /^\d{3}$/.test(val);
                            if (!t) {
                              // hack to disable submit button
                              setSubmittable(false);
                              return "Must be 3 digits";
                            }

                            setSubmittable(true);
                            return null;
                          }}
                          size="small"
                          name={`${group.name}.${i}.${v.fieldName}`}
                          defaultValue={val}
                          type="number"
                        />
                      </Flex>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box marginY={2}>
          <Alert severity="warning">
            Frag Orders does not validate laser codes. Please ensure the laser
            code that is used is valid for your aircraft.
          </Alert>
        </Box>
        <LoadingButton
          disabled={!submittable}
          loading={submitting}
          type="submit"
          variant="contained"
          color="primary"
        >
          Submit
        </LoadingButton>
      </FastForm>
    </div>
  );
}

export default styled(LaserCodeInput).attrs({
  className: LaserCodeInput.name,
})`
  .MuiTable-root {
    table-layout: fixed;
  }

  ${FastInput} input {
    padding: 4px;
  }
`;
