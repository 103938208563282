import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { saveAs } from "file-saver";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { useFindGroupsOfType } from "../../hooks/frag_orders";
import { Module, modules } from "../../lib/data/modules";
import { RadioChannelOverride } from "../../lib/models/Plan";
import { formatFreq } from "../../lib/radios";
import { DCSRadioModulation, FreqBand } from "../../lib/types";
import ControlledForm from "../ControlledForm";
import Flex from "../Flex";
import FormInput from "../FormInput";

type Props = {
  className?: string;
  group: GroupWithPlan;
  onSubmit: (chans: RadioChannelOverride[]) => Promise<void>;
};

function toKey(radioIdx: number, chan: number, key: string) {
  return `${radioIdx}.${chan}.${key}`;
}

function toPresets(formState): RadioChannelOverride[] {
  const presets: RadioChannelOverride[] = [];

  _.each(formState, (v, k) => {
    const [radioIdx, chan, key] = k.split(".");

    if (!presets[radioIdx]) {
      presets[radioIdx] = [];
    }

    if (!presets[radioIdx][chan]) {
      presets[radioIdx][chan] = {};
    }

    if (key === "frequency") {
      presets[radioIdx][chan]["frequency"] = v;
    }

    if (key === "modulation") {
      presets[radioIdx][chan]["modulation"] = v;
    }

    if (key === "description") {
      presets[radioIdx][chan]["description"] = v;
    }
  });

  return presets;
}

function initialFormState(
  module: Module,
  group: GroupWithPlan,
  ovr?: RadioChannelOverride[]
) {
  const s = {};
  const radios = group.units[0].radios;

  let overrides: RadioChannelOverride[] = null;

  if (ovr) {
    overrides = ovr;
  } else if (group.plan && group.plan.radioOverrides) {
    overrides = JSON.parse(group.plan.radioOverrides);
  }

  _.each(radios, (r, i) => {
    const radios = module?.radios;
    if (!radios) {
      return;
    }

    const count = radios[i]?.presetCount || 20;
    _.times(count, (t) => {
      const k = toKey(i, t, "frequency");

      const ovr = overrides && _.get(overrides, [i, t]);
      s[k] = formatFreq(_.get(ovr, "frequency") || r[t]?.frequency);

      const dKey = toKey(i, t, "description");
      s[dKey] = _.get(ovr, "description") || "";
    });
  });

  return s;
}

function RadioPresetForm({ className, group, onSubmit }: Props) {
  const fileInputRef = React.useRef<HTMLInputElement>();
  const m: Module = modules[group.units[0].type];
  const [submitting, setSubmitting] = React.useState(false);
  const [importFrom, setImportFrom] = React.useState(null);
  const [state, setState] = React.useState(null);
  // We need to close the import menu on file upload
  const [importMenuOpen, setImportOpen] = React.useState(false);

  React.useEffect(() => {
    if (!group) {
      setState(null);
      return;
    }

    setState(initialFormState(m, group));
  }, [group.name]);

  const radios = group.units[0].radios;
  const common = useFindGroupsOfType(group?.units[0].type);

  // We can only import from groups that have a plan with radio overrides.
  // Also don't allow for us to import from the group we are currently looking at.
  const commonWithPlan = _.filter(
    common,
    (c) => !!c.plan && !!c.plan.radioOverrides && c.name !== group.name
  );

  if (!m || !m.radios || m.radios?.length === 0) {
    return (
      <Box marginTop={2}>
        <Alert severity="warning">
          Radio configuration not supported for{" "}
          {m?.label || group.units[0].type}
        </Alert>
      </Box>
    );
  }

  const handleSubmit = () => {
    setSubmitting(true);
    const p = toPresets(state);
    onSubmit(p).finally(() => setSubmitting(false));
  };

  const handleImport = () => {
    const imp = _.find(commonWithPlan, { name: importFrom });

    const presets: RadioChannelOverride[] = JSON.parse(
      imp.plan?.radioOverrides
    );

    setState(initialFormState(m, group, presets));
  };

  const handleSave = () => {
    const b = new Blob([JSON.stringify(toPresets(state), null, 2)]);
    saveAs(b, `FragOrders_RadioPreset_${group.name}.json`);
  };

  const handleImporFromFile = () => {
    setImportOpen(false);
    const inpt = fileInputRef?.current;

    inpt.onchange = () => {
      let files = Array.from(inpt.files);
      const f = files[0];

      f.text().then((val) => {
        const presets = JSON.parse(val);
        setState(initialFormState(m, group, presets));
        inpt.value = null;
      });
    };

    inpt.click();
  };

  if (!m.radios) {
    return <div>"No programmable radios found"</div>;
  }

  if (!state) {
    return null;
  }

  return (
    <div className={className}>
      <Flex wide end>
        <div>
          <Box marginRight={4} marginTop={4}></Box>
        </div>
      </Flex>
      <ControlledForm
        state={{ values: state }}
        onChange={(name: string, value: string) => {
          const next = {
            ...state,
            [name]: value,
          };
          setState(next);
        }}
        onSubmit={handleSubmit}
      >
        <Flex wide center>
          {_.map(m?.radios, (r, i: number) => (
            <Box key={i} paddingRight={4}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "center" }} colSpan={4}>
                      {r.label}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ width: 36 }}>Chan</TableCell>
                    <TableCell style={{ width: 90 }}>Freq</TableCell>
                    <TableCell style={{ width: 10 }}>AM/FM</TableCell>
                    <TableCell style={{ width: 120 }}>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.times(r.presetCount || 20, (t) => {
                    const radio = radios[i];
                    if (!radio) {
                      return;
                    }
                    const chan = radio[t];
                    let mod = chan?.modulation;
                    if (!mod) {
                      const band = _.first(r.bands);

                      if (band === FreqBand.VHF_FM) {
                        mod = DCSRadioModulation.FM;
                      }
                    }

                    return (
                      <TableRow key={t}>
                        <TableCell>{t + 1}</TableCell>
                        <TableCell>
                          <FormInput
                            name={toKey(i, t, "frequency")}
                            type="number"
                            size="small"
                            step="any"
                            formatter={(v) => formatFreq(v)}
                          />
                        </TableCell>
                        <TableCell>
                          {mod === DCSRadioModulation.FM ? "FM" : "AM"}
                        </TableCell>
                        <TableCell>
                          <FormInput
                            name={toKey(i, t, "description")}
                            size="small"
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          ))}
        </Flex>
        <Box marginTop={2}>
          <Flex align between wide>
            <Flex align wide>
              <LoadingButton
                loading={submitting}
                type="submit"
                variant="contained"
              >
                Submit
              </LoadingButton>
              <Box marginLeft={2}>
                <Button onClick={handleSave} variant="outlined">
                  Save to File
                </Button>
              </Box>
            </Flex>

            <Box style={{ minWidth: 400 }}>
              <Flex align>
                <Button
                  onClick={handleImport}
                  style={{ marginRight: 4 }}
                  variant="contained"
                  disabled={submitting || !importFrom}
                >
                  Import
                </Button>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">Import</InputLabel>
                  <Select
                    open={importMenuOpen}
                    onOpen={() => setImportOpen(true)}
                    onClose={() => setImportOpen(false)}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={importFrom || ""}
                    label="Import Presets"
                    onChange={(e) => {
                      setImportFrom(e.target.value);
                    }}
                    placeholder="Import Presets"
                  >
                    {_.map(commonWithPlan, (c) => (
                      <MenuItem key={c.name} value={c.name}>
                        {c.name}
                      </MenuItem>
                    ))}
                    <Divider />
                    <MenuItem onClick={handleImporFromFile}>
                      Import from file
                    </MenuItem>
                  </Select>
                </FormControl>
              </Flex>
            </Box>
          </Flex>
        </Box>
      </ControlledForm>
      <input ref={fileInputRef} type="file" style={{ display: "none" }} />
    </div>
  );
}

export default styled(RadioPresetForm).attrs({
  className: RadioPresetForm.name,
})`
  box-sizing: border-box;

  .MuiTable-root {
    min-width: 360px;

    th {
      text-align: left;
      padding: 0 4px;
    }

    td {
      padding: 4px;
    }

    input {
      font-size: 14px;
      padding: 4px;
    }
  }
`;
