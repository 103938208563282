import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { docs } from "../db_util";
import { PlanItem, PlanItemDoc } from "../models/PlanItem";

export const PlanItemCollection = "PlanItems";

export default class PlanManagerV2 {
  private db: Firestore;

  constructor(db: Firestore) {
    this.db = db;
  }

  async list(manifestID: string): Promise<PlanItem[]> {
    const q = query(
      collection(this.db, PlanItemCollection),
      where("manifestID", "==", manifestID)
    );

    return docs<PlanItem>(await getDocs(q));
  }

  async add(discordID: string, item: PlanItem) {
    validate(item);

    const t = new Date().getTime();

    const itemDoc: PlanItemDoc = {
      ...item,
      created_at_timestamp: t,
      updated_at_timestamp: t,
      created_by_uid: discordID,
    };

    return addDoc(collection(this.db, PlanItemCollection), itemDoc);
  }

  async update(item: PlanItemDoc) {
    validate(item);

    const ref = doc(this.db, PlanItemCollection, item.id);

    return await setDoc(ref, item);
  }

  async remove(item: PlanItemDoc) {
    const ref = doc(this.db, PlanItemCollection, item.id);

    return deleteDoc(ref);
  }
}

const validate = (item: PlanItem) => {
  if (!item.manifestID) {
    throw new Error("manifestID is required");
  }

  if (!item.group) {
    throw new Error("group is required");
  }

  if (!item.type) {
    throw new Error("type is required");
  }
};
