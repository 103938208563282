import _ from "lodash";
import * as React from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  FragOrdersQueryKey,
  useAdminGetCurrentFragOrder,
} from "../hooks/frag_orders";
import { FragOrderDoc } from "../lib/models/FragOrder";
import { JobType } from "../lib/models/Job";
import {
  ControlMeasureEditor,
  PlanningRule,
  PublishManifestDoc,
  TaskingState,
} from "../lib/models/PublishManifest";
import { SignupRule } from "../lib/models/SignupRecord";
import { PublishManager } from "../lib/services/PublishManager";
import { Coalition } from "../lib/types";
import { useBundleClient } from "./BundleClientContext";
import { FragOrderContext } from "./FragOrderContext";
import { useUser } from "./UserContext";

type PublishManagerContextType = PublishManager;

export const PublishStatusQueryKey = "PublishStatus";

type Props = {
  children?: any;
  manager: PublishManager;
};

export const PublishManagerContext = React.createContext<
  PublishManagerContextType
>({} as PublishManagerContextType);

export function PublishManagerProvider({ manager, children }: Props) {
  return (
    <PublishManagerContext.Provider value={manager}>
      {children}
    </PublishManagerContext.Provider>
  );
}

export function useGetPublishManager() {
  return React.useContext(PublishManagerContext);
}

export function useGetPublishStatus(fragOrderID: string) {
  const mgr = useGetPublishManager();

  return useQuery([PublishStatusQueryKey, fragOrderID], () =>
    mgr.getPublishStatus(fragOrderID)
  );
}

export function useGetPublishManifest(
  fragOrderID: string,
  coalition: Coalition
) {
  const { data: manifests, ...rest } = useGetPublishStatus(fragOrderID);

  return {
    data: _.find(manifests, { coalition }),
    ...rest,
  };
}

export function useUpdatePublish() {
  const { currentUser } = useUser();
  const mgr = React.useContext(PublishManagerContext);
  const qc = useQueryClient();

  return (fragOrder: FragOrderDoc, coalition: Coalition) => {
    return mgr
      .upsertPublishManifest(
        currentUser.id,
        coalition,
        fragOrder,
        fragOrder.publishOpts
      )
      .then(() => {
        qc.invalidateQueries([PublishStatusQueryKey]);
      });
  };
}

export function useUpdatePlanningRules() {
  const queryClient = useQueryClient();
  const mgr = React.useContext(PublishManagerContext);
  const { data: fragOrder } = useAdminGetCurrentFragOrder();
  const fo = React.useContext(FragOrderContext);
  const { currentUser } = useUser();

  return (manifest: PublishManifestDoc, rule: PlanningRule) => {
    return mgr.upsertPlanningRule(manifest, rule).then(async () => {
      if (rule && !_.includes(fragOrder.jobs, JobType.ApplyPlans)) {
        await fo.update(currentUser.id, [manifest.coalition], {
          ...fragOrder,
          jobs: [...(fragOrder.jobs || []), JobType.ApplyPlans],
        });
        queryClient.invalidateQueries([FragOrdersQueryKey]);
      }
      queryClient.invalidateQueries([PublishStatusQueryKey]);
    });
  };
}

export function useRemovePlanningRule() {
  const queryClient = useQueryClient();
  const mgr = React.useContext(PublishManagerContext);

  return (manifest: PublishManifestDoc, rule: PlanningRule) =>
    mgr.removePlanningRule(manifest, rule.discordUserID).then(async () => {
      queryClient.invalidateQueries([PublishStatusQueryKey]);
    });
}

export function useUpdateControlMeasureEditors() {
  const queryClient = useQueryClient();
  const mgr = React.useContext(PublishManagerContext);

  return (manifest: PublishManifestDoc, editors: ControlMeasureEditor[]) => {
    return mgr.upsertControlMeasureEditors(manifest, editors).then(() => {
      queryClient.invalidateQueries([PublishStatusQueryKey]);
    });
  };
}

export function useUpdateSignupRules() {
  const queryClient = useQueryClient();
  const mgr = React.useContext(PublishManagerContext);

  return (manifest: PublishManifestDoc, rules: SignupRule[]) =>
    mgr.upsertSignupRules(manifest, rules).then(() => {
      queryClient.invalidateQueries([PublishStatusQueryKey]);
    });
}

export function useEnableSignup() {
  const queryClient = useQueryClient();
  const mgr = React.useContext(PublishManagerContext);

  return (manifest: PublishManifestDoc, enabled: boolean) =>
    mgr.toggleSignupsEnabled(manifest, enabled).then(() => {
      queryClient.invalidateQueries([PublishStatusQueryKey]);
    });
}

export function useGetBundleForManifest(manifest?: PublishManifestDoc) {
  const bc = useBundleClient();

  return useQuery<TaskingState, any>(
    [PublishStatusQueryKey, manifest?.fragOrderID, manifest?.bundleAddress],
    async () => {
      if (!manifest) {
        return Promise.resolve(null) as any;
      }

      if (manifest.version !== 2) {
        return JSON.parse(manifest.taskingData);
      }

      const res = await bc.get(manifest);

      return JSON.parse(res);
    }
  );
}
