import { useEffect } from "react";
import { useMap } from "./MapCanvas";

export default function BackgroundLayer() {
  const map = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    // Disabled for now because it requires a MapTiler key
    // const source = new TileJSON({
    //   url: `https://api.maptiler.com/maps/a0f24708-b854-42ee-a567-711c5d1d7513/tiles.json?key=${process.env.MAPTILER_KEY}`,
    //   tileSize: 512,
    //   crossOrigin: "anonymous",
    // });

    // const layer = new Tile({
    //   source,
    // });

    // map.addLayer(layer);
  }, [map]);

  return null;
}
