import _ from "lodash";
import * as React from "react";
import { createContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { PlanItem, PlanItemDoc, PlanItemType } from "../lib/models/PlanItem";
import PlanManagerV2 from "../lib/services/PlanManagerV2";
import { useUser } from "./UserContext";

const PlanManagerV2Context = createContext<PlanManagerV2 | null>(null);

export const PlanManagerV2Provider = ({
  manager,
  children,
}: {
  manager: PlanManagerV2;
  children: React.ReactNode;
}) => {
  return (
    <PlanManagerV2Context.Provider value={manager}>
      {children}
    </PlanManagerV2Context.Provider>
  );
};

export const usePlanManagerV2 = () => {
  const ctx = React.useContext(PlanManagerV2Context);

  if (!ctx) {
    throw new Error("PlanManagerV2Context not found");
  }

  return ctx;
};

const PLAN_ITEMS_QUERY_KEY = "planItems";

export const useAddPlanItem = (manifestID: string) => {
  const pmV2 = usePlanManagerV2();
  const qc = useQueryClient();

  const { currentUser } = useUser();

  return React.useCallback(
    async (item: PlanItem) => {
      await pmV2.add(currentUser.id, item);

      return qc.invalidateQueries([PLAN_ITEMS_QUERY_KEY, manifestID]);
    },
    [pmV2, manifestID]
  );
};

export const useUpdatePlanItem = (manifestID: string) => {
  const pmV2 = usePlanManagerV2();
  const qc = useQueryClient();
  return React.useCallback(
    async (item: PlanItemDoc) => {
      await pmV2.update(item);

      return qc.invalidateQueries([PLAN_ITEMS_QUERY_KEY, manifestID]);
    },
    [pmV2, manifestID]
  );
};

export const useListPlanItems = (manifestID: string) => {
  const pmV2 = usePlanManagerV2();
  return useQuery([PLAN_ITEMS_QUERY_KEY, manifestID], async () =>
    pmV2.list(manifestID)
  );
};

export const useGetPlanItem = (
  manifestID: string,
  groupName: string,
  type: PlanItemType
) => {
  const { data: d, ...rest } = useListPlanItems(manifestID);

  const data = _.find(d, { group: groupName, type: type });

  return {
    data,
    ...rest,
  };
};
