import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { Plan } from "../../lib/models/Plan";

type Props = {
  className?: string;
  onSubmit: (notes: string) => Promise<void>;
  plan: Plan;
};

function MissionNotesInput({ className, onSubmit, plan }: Props) {
  const [submitting, setSubmitting] = React.useState(false);
  const [text, setText] = React.useState(plan?.plannerNotes || "");

  React.useEffect(() => {
    setText(plan?.plannerNotes || "");
  }, [plan?.groupName]);

  const handleSubmit = () => {
    setSubmitting(true);

    onSubmit(text).finally(() => setSubmitting(false));
  };

  return (
    <div className={className}>
      <p>
        Add notes that will be displayed in the Frag Order and generated
        kneeboards
      </p>
      <TextField
        multiline
        fullWidth
        rows={12}
        onChange={(ev) => setText(ev.target.value)}
        value={text || ""}
      />
      <Box my={1}>
        <LoadingButton
          loading={submitting}
          onClick={handleSubmit}
          variant="contained"
        >
          Submit
        </LoadingButton>
      </Box>
    </div>
  );
}

export default styled(MissionNotesInput).attrs({
  className: MissionNotesInput.name,
})`
  width: 100%;
  min-height: 820px;

  .MuiTextField-root {
    width: 100%;

    textarea {
      font-family: monospace;
    }
  }
`;
