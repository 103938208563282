import JSZip from "jszip";
import _ from "lodash";
import * as lua from "../../vendor/lua-in-js";
import { SerializeLuaTable } from "./print";
import { getTaskingState } from "./tasking";
import { Coalition, LuaTable } from "./types";

interface Script {
  exec: any;
}

export type LuaEnv = {
  parse: (script: string) => Script;
  parseFile: (path: string) => Script;
  loadLib: (name: string, value: lua.Table) => void;
};

export function getASTForEnv(luaEnv: LuaEnv, varName: string = "mission") {
  let result: LuaTable = null;

  const myLib = new lua.Table({
    getAST: (table) => {
      result = table;
    },
  });

  luaEnv.loadLib("myLib", myLib);

  luaEnv.parse(`myLib.getAST(${varName})`).exec();

  return result;
}

export function getASTByVarName(luaStr: string, luaVar: string): LuaTable {
  const luaEnv = getLuaEnv(luaStr, luaVar);

  return getASTForEnv(luaEnv, luaVar);
}

export function getMissionAST(missionLua: string): LuaTable {
  if (!missionLua) {
    throw new Error("Mission lua string was undefined");
  }

  return getASTByVarName(missionLua, "mission");
}

// Backticks create parsing issues.
// The most common occurence is livery names like `desert`.
// This will break those livery settings, but the upside is that missions will parse.
// The trade-off is no exceptions being thrown, but breaking livery settings in some cases.
function sanitizeBackticks(str: string): string {
  return str.replace(/`(.*)`/g, "");
}

export function getLuaEnv(
  luaStr: string,
  tableName: string = "mission"
): LuaEnv {
  const luaEnv = lua.createEnv();
  luaEnv.parse(SerializeLuaTable(tableName)).exec();
  luaEnv.parse(sanitizeBackticks(luaStr)).exec();

  return luaEnv;
}

export type LuaFunc = (tbl: LuaTable) => void;

export function runFunc(
  luaEnv: LuaEnv,
  customFunc: LuaFunc,
  luaVar: string = "mission"
) {
  const customLib = new lua.Table({ customFunc });
  luaEnv.loadLib("customLib", customLib);

  luaEnv.parse(`customLib.customFunc(${luaVar})`).exec();
  return printMission(luaEnv);
}

/* eslint-disable-next-line */
const backslashRegex = /\\\\\"/g;
const commentRegex = /-- end of \[(.*)\],/g;
const inlineLuaRegex = /\\\\\n/g;

export function printMission(luaEnv: LuaEnv): string {
  const output = luaEnv
    .parse(`return "mission =" .. SerializeTable(mission)`)
    .exec();

  // The PrintTable function's recursiveness appends a trailing `,` to comments.
  // Remove it in JS land, since we don't have regex in Lua land
  let clean = output.replace(commentRegex, (match) => match.replace(",", ""));

  // Because the miz file has "lua within lua" in the form of actions,
  // we need to replace all \\" occurrences with \\\".
  clean = clean.replace(backslashRegex, '\\\\\\"');

  // Actions have inline lua that can have newline characters that cause issues.
  clean = clean.replace(inlineLuaRegex, "\\\\\\\n");

  return clean;
}

export type DCSI18n = { [k: string]: string };

export function convertDictionaryToJS(dictionary: string): DCSI18n {
  const luaEnv = getLuaEnv(dictionary, "dictionary");

  let obj = {};

  const myLib = new lua.Table({
    getAST: (dictionaryAst: LuaTable) => {
      _.each(dictionaryAst.strValues, (v, k) => {
        obj[k] = v;
      });
    },
  });

  luaEnv.loadLib("myLib", myLib);

  luaEnv.parse(`myLib.getAST(dictionary)`).exec();

  return obj;
}

export async function getTaskingFromMiz(coalition: Coalition, miz: File) {
  const zip = await JSZip.loadAsync(miz);
  const [mission, dictionary, warehouses] = await getMission(zip);
  const ts = getTaskingState(
    coalition,
    mission,
    warehouses,
    convertDictionaryToJS(dictionary)
  );

  return ts;
}

export const getMission = (
  zip: JSZip,
  cb?: (mission: string, dictionary: string, warehouses: string) => void
): Promise<string[]> => {
  if (!zip) {
    return null;
  }
  const p = [];

  zip.forEach((path, e) => {
    // Use array indices to ensure a consistent order every time.
    if (path === "mission") {
      p[0] = e.async("text");
    }

    if (path === "l10n/DEFAULT/dictionary") {
      p[1] = e.async("text");
    }

    if (path === "warehouses") {
      p[2] = e.async("text");
    }
  });

  return Promise.all(p).then(([mission, dictionary, warehouses]) => {
    // This assumes mission will always be pushed first.
    // Not sure if that is a safe assumption or not...
    if (cb) {
      cb(mission, dictionary, warehouses);
    }

    // Hack to make this promise-able
    return [mission, dictionary, warehouses];
  });
};

export const dictionaryToLua = (dictionary: DCSI18n): string => {
  // TODO: hacky JS to Lua transpile that will only work
  // in this specific case.
  let luaStr = "dictionary = \n{\n";

  _.each(dictionary, (v, k) => {
    luaStr += `\t["${k}"] = "${v}",\n`;
  });

  luaStr += "} -- end of dictionary";

  return luaStr;
};
