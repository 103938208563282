import { Extent } from "ol/extent";
import * as React from "react";

import JSZip from "jszip";
import _ from "lodash";
import * as ReactDOMClient from "react-dom/client";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { ExternalDataRecord } from "../../lib/models/ExternalDataRecord";
import { FragOrderDoc } from "../../lib/models/FragOrder";
import { JobType } from "../../lib/models/Job";
import { PlanItem } from "../../lib/models/PlanItem";
import { TaskingState } from "../../lib/models/PublishManifest";
import { RadioPresetConfig } from "../../lib/models/RadioPresetConfig";
import { NewHTMLImageGenerator } from "../../lib/services/HTMLImageGenerator";
import { NewImageComposer } from "../../lib/services/ImageComposer";
import { JobFileNameLookup } from "../../lib/services/JobRunner";
import { MapImager } from "../../lib/services/MapImager";
import { Coalition } from "../../lib/types";
import MiniMap from "../MiniMap";
import KneeboardCommsCard from "./KneeboardCommsCard";
import { renderKneeboard } from "./kneeboard_preview";

export const KNEEBOARD_HEIGHT = 957;
export const KNEEBOARD_WIDTH = 717;

export async function renderMap(
  extent: Extent,
  opts: {
    height: number;
    width: number;
    zoomAdjust?: number;
  },
  children: any,
  asURL?: boolean
) {
  if (!extent) {
    throw new Error("Extent is required");
  }
  return new Promise<string | Blob>((accept) => {
    const el = document.createElement("div");
    document.body.appendChild(el);
    const root = ReactDOMClient.createRoot(el);
    root.render(
      <MiniMap
        disableRender
        onMount={async (ctrl: MapImager) => {
          if (asURL) {
            const uri = await ctrl.toImageURL();
            accept(uri);
          } else {
            const blob = await ctrl.toImage();
            accept(blob);
          }
        }}
        height={opts.height}
        width={opts.width}
        extent={extent}
        zoomAdjust={opts.zoomAdjust}
      >
        {children}
      </MiniMap>
    );
  });
}

export async function renderBriefingTextKneeboard(
  tasking: TaskingState,
  title: string
) {
  const g = NewHTMLImageGenerator();
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);
  const el = (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          fontFamily: "sans-serif",
          justifyContent: "center",
        }}
      >
        <h2>{title} - Mission Briefing</h2>
      </div>
      <pre style={{ whiteSpace: "pre-wrap", width: 717 - 16, fontSize: 16 }}>
        {tasking.briefingText}
      </pre>
    </div>
  );

  const blob = await g.renderToBlob(el, {
    height: KNEEBOARD_HEIGHT,
    width: KNEEBOARD_WIDTH,
  });

  composer.add(blob, { x: 10, y: 0 });

  return composer;
}

export async function renderCommsCard(group: GroupWithPlan) {
  const g = NewHTMLImageGenerator();
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);
  const el = <KneeboardCommsCard group={group} />;

  const blob = await g.renderToBlob(el, {
    height: KNEEBOARD_HEIGHT,
    width: KNEEBOARD_WIDTH - 8,
  });

  composer.add(blob, { x: 0, y: 0 });

  return composer;
}

export const styles = {
  table: {
    textAlign: "left",
    width: "100%",
    borderCollapse: "collapse",
    tableLayout: "fixed",
    border: "1px solid black",
  },
  th: {
    fontSize: 12,
    border: "1px solid black",
  },
  td: {
    border: "1px solid gray",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export async function generateKneeboardPack(
  fo: FragOrderDoc,
  coa: Coalition,
  title: string,
  tasking: TaskingState,
  external: ExternalDataRecord[],
  planItems: PlanItem[],
  zip: JSZip
) {
  const jobs = fo.jobs;

  const perGroupJobs = _.filter(jobs, (j) => {
    return _.includes(
      [
        JobType.GroupCommsCard,
        JobType.GroupLineupCard,
        JobType.InjectRouteDetailCards,
      ],
      j
    );
  });

  const otherJobs = _.filter(jobs, (j) => {
    return !_.includes(perGroupJobs, j);
  });

  for (const group of tasking.plannedGroups as GroupWithPlan[]) {
    for (const job of perGroupJobs) {
      const settings = fo.jobSettings[job];

      const composer = await renderKneeboard(
        job,
        tasking,
        {
          group,
          title,
          radioConfig: fo.jobSettings[
            JobType.RadioPresetConfig
          ] as RadioPresetConfig,
        },
        settings,
        external
      );
      const blob = await composer.renderToBlob();
      const name = JobFileNameLookup[job];
      zip.file(`${group.name}/${name(group.name)}.png`, blob);
    }
  }

  for (const job of otherJobs) {
    const settings = fo.jobSettings[job];
    const composer = await renderKneeboard(
      job,
      tasking,
      {
        title,
        radioConfig: fo.jobSettings[
          JobType.RadioPresetConfig
        ] as RadioPresetConfig,
      },
      settings,
      external
    );

    if (!composer) {
      continue;
    }
    const blob = await composer.renderToBlob();
    const name = JobFileNameLookup[job];
    zip.file(`${name()}.png`, blob);
  }
}
