import _ from "lodash";
import { Feature } from "ol";
import { extend } from "ol/extent";
import { Point } from "ol/geom";
import { Vector as VectorSource } from "ol/source";
import { toAirbaseFeature } from "../components/MapCanvas/AirbaseLayer";
import { toPoint } from "../components/MapCanvas/RouteLayer";
import { Airbase } from "./data/airbases";
import { Theater, convertDCSToLatLong } from "./map";
import { DCSGroupData } from "./types";

export function getAirbaseExtent(theater: Theater, airbases: Airbase[]) {
  if (!airbases || airbases.length === 0) {
    return null;
  }
  const features = _.map(airbases, toAirbaseFeature(theater, false));
  const source = new VectorSource({
    features: _.map(features, (f) => new Feature({ ...f })),
  });
  return source.getExtent();
}

export function getRouteExtent(theater: Theater, group: DCSGroupData) {
  const features = _.map(group.waypoints, toPoint(group, theater));

  const source = new VectorSource({
    features: _.map(features, (f) => new Feature({ ...f })),
  });
  return source.getExtent();
}

export function getEntireExtent(theater: Theater, groups: DCSGroupData[]) {
  let extent;

  for (const g of groups) {
    const features = _.map(g.waypoints, toPoint(g, theater));

    const source = new VectorSource({
      features: _.map(features, (f) => new Feature({ ...f })),
    });

    const e = source.getExtent();

    if (!extent) {
      // don't have one yet, nothing to exend
      extent = e;
      continue;
    }

    extent = extend(extent, e);
  }

  return extent;
}

export function getParkingExtent(theater: Theater, groups: DCSGroupData[]) {
  const features = _.map(groups, (g) => {
    const { long, lat } = convertDCSToLatLong(theater, {
      x: g.x,
      y: g.y,
    });

    return {
      geometry: new Point([long, lat]),
      name: g.name,
    };
  });

  const source = new VectorSource({
    features: _.map(features, (f) => new Feature({ ...f })),
  });
  return source.getExtent();
}
