import * as React from "react";
import { getAirbaseExtent } from "../../lib/extent";
import { AirbasesKneeboardSettings } from "../../lib/models/Job";
import { TaskingState } from "../../lib/models/PublishManifest";
import { NewHTMLImageGenerator } from "../../lib/services/HTMLImageGenerator";
import {
  ImageComposer,
  NewImageComposer,
} from "../../lib/services/ImageComposer";
import AirbaseLayer from "../MapCanvas/AirbaseLayer";
import SharedNavPointsLayer from "../MapCanvas/SharedNavPointsLayer";
import KneeboardAirbasesTable from "./KneeboardAirbasesTable";
import {
  KNEEBOARD_HEIGHT,
  KNEEBOARD_WIDTH,
  renderMap,
} from "./kneeboard_utils";

export async function renderAirbasesKneeboard(
  tasking: TaskingState,
  jobSettings?: AirbasesKneeboardSettings
): Promise<ImageComposer> {
  const composer = NewImageComposer(KNEEBOARD_HEIGHT, KNEEBOARD_WIDTH);

  const el = (
    <KneeboardAirbasesTable
      settings={jobSettings}
      theater={tasking.theater}
      bases={tasking?.airbases}
    />
  );
  const g = NewHTMLImageGenerator();

  const tblBlob = await g.renderToBlob(el, {
    height: KNEEBOARD_HEIGHT / 2 + 100,
    width: KNEEBOARD_WIDTH,
  });

  let ext = getAirbaseExtent(tasking.theater, tasking?.airbases);

  const mapImg = (await renderMap(
    ext,
    {
      height: (KNEEBOARD_HEIGHT - 100) / 2,
      width: KNEEBOARD_WIDTH,
      zoomAdjust: -1.2,
    },
    <>
      <AirbaseLayer
        isOpfor={false}
        theater={tasking?.theater}
        bases={tasking?.airbases}
        color="black"
        renderFrequencies={jobSettings?.showAirbaseFreqs}
      />

      <SharedNavPointsLayer
        theater={tasking.theater}
        bullseye={tasking.bullseye}
      />
    </>
  )) as Blob;

  composer.add(tblBlob, { x: 0, y: 0 });
  composer.add(mapImg, { x: 0, y: (KNEEBOARD_HEIGHT + 100) / 2 });

  return composer;
}
