import * as React from "react";
import styled from "styled-components";

import { Box } from "@mui/material";
import _ from "lodash";
import { DateTime } from "luxon";
import { DataSet, Timeline, TimelineItem } from "vis-timeline/standalone";
import {
  GroupWithPlan,
  useListMergedGroups,
} from "../../contexts/PlanManagerContext";
import { useGetCurrentTaskingData } from "../../contexts/PublishReaderContext";
import {
  useFocusWaypoint,
  useGetColorMap,
  useTaskingState,
} from "../../contexts/TaskingMapContext";
import { Theater, convertDCSToLatLong } from "../../lib/map";
import { getTimeZoneOffset } from "../../lib/tasking";
import {
  durationFromLast,
  getDistanceFromLatLonInNautical,
  parseMizTime,
} from "../../lib/time";
import { DCSWaypoint } from "../../lib/types";
import Flex from "../Flex";
import PlanningModeButton from "../NavigationButtons/PlanningModeButton";
import TimelineStyleWrapper from "./TimelineStyleWrapper";

type Props = {
  className?: string;
};

type FOTimelineItem = {
  group: string;
  number: number;
  start: Date;
} & TimelineItem;

function TimelineEditor({ className }: Props) {
  const { controller } = useTaskingState();
  const {
    state: { userPlannableGroups, planningMode },
  } = useTaskingState();
  const timelineRef = React.useRef<Timeline>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { data: tasking } = useGetCurrentTaskingData();
  const { data: groups } = useListMergedGroups();

  // Make the group names work as CSS classes
  const colorMap = _.reduce(
    useGetColorMap(),
    (acc, value, key) => {
      acc[_.kebabCase(key)] = value;
      return acc;
    },
    {}
  );

  const focus = useFocusWaypoint();
  const offset = getTimeZoneOffset(tasking.theater);
  const start = parseMizTime(tasking.time).minus({ hours: offset });

  React.useEffect(() => {
    const tl = timelineRef.current;

    if (!tl) {
      return;
    }

    tl.setItems(
      new DataSet(
        createItemsList(
          groups,
          start,
          colorMap,
          userPlannableGroups,
          planningMode,
          tasking.theater
        )
      )
    );
  }, [groups, planningMode]);

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (timelineRef.current) {
      return;
    }

    const items = createItemsList(
      groups,
      start,
      colorMap,
      userPlannableGroups,
      planningMode,
      tasking.theater
    );

    const groupNames = _.map(groups, (g) => ({
      id: g.name,
      content: g.name,
      className: _.kebabCase(g.name),
    }));
    const ds = new DataSet(items);

    const timeline = new Timeline(containerRef.current, ds, groupNames, {
      start: start.toJSDate(),
      editable: {
        add: false,
        updateTime: planningMode,
        updateGroup: false,
        remove: false,
      },
      template: (item, ele: HTMLElement) => {
        const parent = ele.parentElement;

        parent.style.borderColor = item.color;
        const d = DateTime.fromJSDate(item.start);

        const utc = d.toUTC();

        const el = document.createElement("div");

        // el.className = "vis-item";
        el.style.padding = `4px`;
        el.appendChild(document.createTextNode(item.label));
        el.appendChild(document.createElement("br"));
        if (!item.isDuration) {
          el.style.color = item.color;
          el.appendChild(document.createTextNode(utc.toFormat("HH:mm:ss")));
        }

        return el;
      },
      format: {
        majorLabels: (date) =>
          // This is a moment object, which is a bug in the lib
          // @ts-ignore
          DateTime.fromJSDate(date.toDate()).toUTC().toFormat("HH:mm:ss"),

        minorLabels: (date) =>
          // This is a moment object, which is a bug in the lib
          // @ts-ignore
          DateTime.fromJSDate(date.toDate()).toUTC().toFormat("HH:mm:ss"),
      },
      snap: null,
      autoResize: true,
      onMove: (item: FOTimelineItem, cb) => {
        const dt = DateTime.fromJSDate(item.start);
        const existing = controller.current
          ?.getWaypoint(item.group, item.number)
          .getProperties();

        const nextETA = dt.diff(start).as("seconds");

        controller.current?.setWaypointDetails(item.group, item.number, {
          altitude: existing?.altitude || 0,
          text: existing?.name || item.number.toString(),
          altitudeType: existing?.altitudeType || "AGL",
          eta: nextETA,
        });

        cb(item);
      },
    });

    timeline.on("select", function (properties) {
      const item = _.first(properties.items) as string;

      if (!item || item.includes("-dur")) {
        return;
      }

      const [group, number] = item.split("/");

      const wp = controller.current?.findWaypointForGroup(
        group,
        parseInt(number)
      );

      if (!wp) {
        // Controller could not find the waypoint
        // The user probably cannot plan this group.
        // Focus the normal map layer group instead
        const g = _.find(groups, { name: group });
        const unplannableWP = _.find(g?.waypoints, {
          number: parseInt(number),
        });

        focus(group, unplannableWP as DCSWaypoint);
        return;
      }
      focus(group, wp.getProperties() as DCSWaypoint);
    });

    timelineRef.current = timeline;
  }, [containerRef.current]);
  return (
    <div className={className}>
      <Box p={1}>
        <Flex align wide end>
          <Box mr={2}>Mode: {planningMode ? "Planning" : "Viewing"}</Box>
          <PlanningModeButton />
        </Flex>
      </Box>
      <TimelineStyleWrapper colorMap={colorMap}>
        <div ref={containerRef} />
      </TimelineStyleWrapper>
    </div>
  );
}

export default styled(TimelineEditor).attrs({
  className: TimelineEditor.name,
})`
  .vis-item {
    background-color: white;
  }
  .vis-selected {
    background-color: #fff785;
    border-color: #ffc200;
  }
`;

function createItemsList(
  groups: GroupWithPlan[],
  start: DateTime,
  colorMap: any,
  userPlannableGroups: string[],
  planningMode: boolean,
  theater: Theater
) {
  const items = [];
  for (let i = 0; i < groups.length; i++) {
    const group = groups[i];

    for (let w = 0; w < group.waypoints.length; w++) {
      const wp = group.waypoints[w];
      const eta = start.plus({ seconds: wp.ETA });
      const color = colorMap[_.kebabCase(group.name)];

      if (wp.number === 0) {
        continue;
      }

      const prev = group.waypoints[w - 1];

      const dur = durationFromLast(prev, wp);
      const coord1 = convertDCSToLatLong(theater, prev);
      const coord2 = convertDCSToLatLong(theater, wp);
      const distance = getDistanceFromLatLonInNautical(coord1, coord2);
      // Get ground speed based on distance and duration
      const speed = distance / dur.as("hours");

      const durItem = {
        id: `${group.name}/${wp.number}-dur`,
        label: `${dur.toFormat("mm:ss")} - ${distance.toFixed(
          1
        )}nm @ ${speed.toFixed()} knots`,
        start: start.plus({ seconds: prev.ETA }).toJSDate(),
        end: eta.toJSDate(),
        isDuration: true,
        color,
        group: group.name,
        editable: false,
        selectable: false,
      };

      items.push(durItem);

      const editable = planningMode && userPlannableGroups.includes(group.name);

      items.push({
        id: `${group.name}/${wp.number}`,
        number: wp.number,
        label:
          wp.name && wp.name !== wp.number.toString()
            ? `${wp.number}: ${wp.name}`
            : wp.number.toString(),
        color,
        start: eta.toJSDate(),
        group: group.name,
        editable: {
          add: false,
          updateTime: editable,
          remove: false,
        },
      });
    }
  }

  return items;
}
