import AddCircleIcon from "@mui/icons-material/AddCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import { Button, FormGroup } from "@mui/material";
import { Box } from "@mui/system";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useNotification } from "../../contexts/NotifcationContext";
import {
  useGetBundleForManifest,
  useRemovePlanningRule,
  useUpdatePlanningRules,
} from "../../contexts/PublishManagerContext";

import {
  PlanningRule,
  PublishManifestDoc,
} from "../../lib/models/PublishManifest";
import { AppRoutes } from "../../lib/types";
import DataTable from "../DataTable";
import Flex from "../Flex";
import Link from "../Link";
import PlanningRuleModal from "./PlanningRuleModal";

type Props = {
  className?: string;
  discordGuildID: string;
  manifest: PublishManifestDoc;
  fragOrderID: string;
};

function PlanningManagementForm({
  className,
  manifest,

  fragOrderID,
}: Props) {
  const [removing, setRemoving] = React.useState<PlanningRule>(null);
  const update = useUpdatePlanningRules();
  const remove = useRemovePlanningRule();
  const [updating, setUpdating] = React.useState(false);
  const [editing, setEditing] = React.useState<PlanningRule>(null);
  const [creating, setCreating] = React.useState<PlanningRule>(null);
  const { success, error } = useNotification();
  const { data: tasking } = useGetBundleForManifest(manifest);

  if (!manifest) {
    return (
      <Flex wide center>
        <Flex column align>
          <h4>This Frag Order has not been published.</h4>
          <Link to={`${AppRoutes.FragOrderDetail}/${fragOrderID}/publish`}>
            <Button variant="contained">View Publish Options</Button>
          </Link>
        </Flex>
      </Flex>
    );
  }

  const handleRemove = (rule: PlanningRule) => {
    if (window.confirm("Are you sure you want to remove this rule?")) {
      setRemoving(rule);

      remove(manifest, rule).then(() => setRemoving(null));
    }
  };

  const handleEditSubmit = () => {
    setUpdating(true);

    update(manifest, { ...editing })
      .then(() => {
        success("Planning rules updated.");
        setEditing(null);
      })
      .catch((e) => error(e.message))
      .finally(() => setUpdating(false));
  };

  const handleCreateSubmit = () => {
    if (!creating.discordUserID) {
      error("You must select a user to create a planning rule.");
      return;
    }

    setUpdating(true);

    update(manifest, { ...creating })
      .then(() => {
        success("Planning rules updated.");
        setCreating(null);
      })
      .catch((e) => error(e.message))
      .finally(() => setUpdating(false));
  };

  return (
    <div className={className}>
      <DataTable
        columns={[
          { value: "discordDisplayText", label: "Username" },
          {
            value: (r: PlanningRule) =>
              `${r.plannableGroupNames.length} groups`,
            label: "Plannable Groups",
          },
          {
            label: "",
            width: 64,
            value: (r: PlanningRule) => (
              <Button
                variant="outlined"
                onClick={() => setEditing(_.cloneDeep(r))}
              >
                Edit
              </Button>
            ),
          },
          {
            value: (r: PlanningRule) => (
              <LoadingButton
                loading={
                  removing?.discordUserID === r.discordUserID ||
                  removing?.discordRoleID === r.discordRoleID
                }
                onClick={() => handleRemove(r)}
                variant="outlined"
                color="error"
              >
                Remove
              </LoadingButton>
            ),
            label: "",
            width: 64,
          },
        ]}
        rows={manifest.planningRules}
      />
      <FormGroup>
        <Box marginBottom={4}>
          <Box marginTop={2}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                setCreating({
                  discordDisplayText: "",
                  discordUserID: null,
                  plannableGroupNames: [],
                  isRoleRule: false,
                  discordRoleID: null,
                });
              }}
              startIcon={<AddCircleIcon />}
            >
              Add Planning Rule
            </Button>
          </Box>
        </Box>
      </FormGroup>

      <PlanningRuleModal
        open={!!editing}
        onClose={() => setEditing(null)}
        actionType="edit"
        updating={updating}
        onChange={(rule) => setEditing({ ...editing, ...rule })}
        onSubmit={handleEditSubmit}
        rule={editing}
        groups={_.map(tasking?.plannedGroups, "name")}
      />
      <PlanningRuleModal
        open={!!creating}
        onClose={() => setCreating(null)}
        actionType="create"
        updating={updating}
        onChange={(rule) => setCreating({ ...editing, ...rule })}
        onSubmit={handleCreateSubmit}
        rule={creating}
        groups={_.map(tasking?.plannedGroups, "name")}
        guildID={manifest.discordGuildID}
      />
      {/* <Modal open={!!editing} onClose={() => setEditing(null)}>
        <ModalContent style={{ minWidth: 480 }}>
          <Section title={`Edit Planning Rule`}>
            <Box p={2}>
              <Box mb={2}>
                <FormLabel>Username</FormLabel>
                <p>{editing?.discordDisplayText}</p>
              </Box>

              <GroupSelect
                onChange={(groups) => {
                  setEditing({
                    ...editing,
                    plannableGroupNames: groups,
                  });
                }}
                value={editing?.plannableGroupNames}
                groups={_.map(tasking?.plannedGroups, "name")}
              />
            </Box>
            <Box>
              <LoadingButton
                loading={updating}
                type="submit"
                variant="contained"
                onClick={handleEditSubmit}
              >
                Save
              </LoadingButton>
            </Box>
          </Section>
        </ModalContent>
      </Modal> */}
    </div>
  );
}

export default styled(PlanningManagementForm).attrs({
  className: PlanningManagementForm.name,
})`
  .MuiAutocomplete-root {
    min-width: 360px;
    /* margin-right: 36px; */
  }

  .MuiInputBase-root {
    min-width: 120px;
  }

  .MuiFormControl-root {
    box-sizing: border-box;
  }

  .MuiButton-root {
    text-decordation: none;
  }

  .MuiFormGroup-root .MuiBox-root {
    padding: 0;
  }
`;
