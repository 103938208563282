import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Icon, LinkProps, Tooltip } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import Link from "./Link";

type Props = {
  className?: string;
  to?: string;
  tooltip?: string;
} & LinkProps;

function DocsHelpIcon({
  className,
  to,
  tooltip = "View the docs",
  ...rest
}: Props) {
  return (
    <Tooltip title={tooltip}>
      <Link {...rest} className={className} target="_blank" href={to}>
        <Icon>
          <HelpOutlineIcon fontSize="small" />
        </Icon>
      </Link>
    </Tooltip>
  );
}

export default styled(DocsHelpIcon).attrs({ className: DocsHelpIcon.name })``;
