import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Button, FormLabel, Modal } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { PlanningRule } from "../../lib/models/PublishManifest";
import Flex from "../Flex";
import ModalContent from "../ModalContent";
import Section from "../Section";
import GroupSelect from "./GroupSelect";
import GuildMemberAutocomplete from "./GuildMemberAutocomplete";

type Props = {
  className?: string;
  open: boolean;
  onClose: () => void;
  rule: PlanningRule;
  actionType: "create" | "edit";
  onChange: (rule: PlanningRule) => void;
  groups: string[];
  onSubmit: () => void;
  updating: boolean;
  guildID?: string;
};

function PlanningRuleModal({
  className,
  open,
  onClose,
  rule,
  actionType,
  onChange,
  onSubmit,
  groups,
  updating,
  guildID,
}: Props) {
  return (
    <Modal className={className} open={open} onClose={onClose}>
      <ModalContent style={{ minWidth: 480 }}>
        <Section
          title={
            actionType === "create"
              ? "Create Planning Rule"
              : "Edit Planning Rule"
          }
        >
          <Box p={2}>
            <Box mb={2}>
              {actionType === "create" ? (
                <GuildMemberAutocomplete
                  guildID={guildID}
                  discordID={rule?.discordUserID}
                  displayName={rule?.discordDisplayText}
                  onChange={(user) =>
                    onChange({
                      ...rule,
                      discordUserID: user.discordUserID,
                      discordDisplayText: user.displayName,
                    })
                  }
                />
              ) : (
                <>
                  <FormLabel>Username</FormLabel>
                  <p>{rule?.discordDisplayText}</p>
                </>
              )}
            </Box>

            <GroupSelect
              onChange={(val) => {
                onChange({
                  ...rule,
                  plannableGroupNames: val,
                });
              }}
              value={rule?.plannableGroupNames}
              groups={groups}
            />
          </Box>
          <Flex align wide between>
            <LoadingButton
              disabled={!rule?.discordUserID}
              loading={updating}
              type="submit"
              variant="contained"
              onClick={onSubmit}
            >
              Save
            </LoadingButton>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Flex>
        </Section>
      </ModalContent>
    </Modal>
  );
}

export default styled(PlanningRuleModal).attrs({
  className: PlanningRuleModal.name,
})`
  ${GroupSelect} {
    max-height: 640px;
  }
`;
