import SaveIcon from "@mui/icons-material/Save";
import { Tooltip } from "@mui/material";
import saveAs from "file-saver";
import _ from "lodash";
import * as React from "react";
import styled from "styled-components";
import { useGroupsWithPlans } from "../../contexts/PlanManagerContext";
import {
  useGetCurrentManifest,
  useGetCurrentTaskingData,
} from "../../contexts/PublishReaderContext";
import { convertGroupToDTCFile } from "../../lib/dtc";
import { PlanStatus } from "../../lib/models/Plan";
import { predictRadioPresets } from "../../lib/radios";
import { parseMizTime } from "../../lib/time";
import { DCSGroupData } from "../../lib/types";
import NoShadowStateButton from "../NavigationButtons/NoShadowStateButton";

type Props = {
  className?: string;
  group: DCSGroupData;
};

function DownloadDTCFileButton({ className, group }: Props) {
  const { data: manifest } = useGetCurrentManifest();
  const { data: tasking } = useGetCurrentTaskingData();
  const { data: groupsWithPlans } = useGroupsWithPlans(
    manifest?.fragOrderID,
    tasking?.plannedGroups,
    { status: PlanStatus.Submitted }
  );

  if (!groupsWithPlans || !tasking || !manifest) {
    return null;
  }

  const g = _.find(groupsWithPlans, { name: group?.name });

  let presets;

  if (g?.plan?.radioOverrides) {
    presets = JSON.parse(g.plan.radioOverrides);
  } else if (tasking.radioPresets) {
    presets = predictRadioPresets(g, tasking.radioPresets);
  }

  return (
    <Tooltip title="Download DTC file">
      <NoShadowStateButton
        className={className}
        onClick={() => {
          const dtc = convertGroupToDTCFile(
            tasking.theater,
            parseMizTime(tasking.time),
            group,
            presets
          );

          saveAs(
            new Blob([JSON.stringify(dtc, null, 2)], {
              type: "application/json",
            }),
            `${group.name}.json`
          );
        }}
      >
        <SaveIcon />
      </NoShadowStateButton>
    </Tooltip>
  );
}

export default styled(DownloadDTCFileButton).attrs({
  className: DownloadDTCFileButton.name,
})``;
