import _ from "lodash";
import { Feature, Map } from "ol";
// @ts-ignore
import { Geometry, Point } from "ol/geom";
import { Draw } from "ol/interaction";
import "ol/ol.css";
import VectorSource from "ol/source/Vector";
import { Fill, RegularShape, Stroke, Style, Text } from "ol/style";
import { Theater } from "../../map";
import { configurePointFeature } from "../../services/PlanningController";
import { eventIsRightClick } from "./helpers";

const tipStyle = new Style({
  image: new RegularShape({
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    stroke: new Stroke({
      color: "rgba(0, 0, 0, 1)",
      width: 2,
    }),
    points: 4,
    radius: 10,
    rotation: Math.PI / 2,
    angle: 0,
  }),
  text: new Text({
    text: "Click to drop waypoint. Esc exit to drop mode",
    font: "12px Roboto,sans-serif",
    fill: new Fill({
      color: "rgba(255, 255, 255, 1)",
    }),
    backgroundFill: new Fill({
      color: "rgba(0, 0, 0, 0.4)",
    }),
    padding: [4, 4, 4, 4],
    textAlign: "left",
    offsetX: 15,
  }),
});

export function addWaypointDropInteraction(
  map: Map,
  source: VectorSource<Geometry>,
  groupName: string,
  originWP: number,
  theater: Theater
) {
  let origin = originWP;
  const draw = new Draw({
    source: source,
    type: "Point",
    style: tipStyle,
    condition: (e) => {
      if (eventIsRightClick(e)) {
        return false;
      }
      return true;
    },
  });

  draw.on("drawstart", (e) => {
    e.feature.set("group", groupName);

    const num = origin + 1;
    e.feature.set("number", num);
    const all = source.getFeatures() as Feature<Point>[];

    configurePointFeature(groupName, theater)(e.feature as any, num, all);
    const after = _.filter(all, (f) => f.get("number") >= num);
    if (after) {
      _.each(after, (f, i, ary) =>
        configurePointFeature(groupName, theater)(
          f as any,
          f.get("number") + 1,
          ary
        )
      );
    }

    origin = num;
  });

  map.addInteraction(draw);
  return draw;
}
