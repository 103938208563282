import JSZip from "jszip";
import { renderLineupCard } from "../../components/Kneeboards/kneeboard_lineup";
import { mergePlansWithGroups } from "../../contexts/PlanManagerContext";
import { ExternalDataRecord } from "../models/ExternalDataRecord";
import { GroupLineupCardSettings, MizJobFunc } from "../models/Job";
import { PlanDoc, PlanStatus } from "../models/Plan";
import { PlanItem } from "../models/PlanItem";
import { TaskingState } from "../models/PublishManifest";

export const injectLineupCards = (
  tasking: TaskingState,
  plans: PlanDoc[],
  jobSettings?: GroupLineupCardSettings,
  radioConfig?: string,
  externalData?: ExternalDataRecord[],
  planItems?: PlanItem[]
): MizJobFunc => async (zip: JSZip) => {
  const groups = mergePlansWithGroups(plans, tasking.plannedGroups, {
    status: PlanStatus.Submitted,
  });

  for (const group of groups) {
    const type = group.units[0].type;
    const composer = await renderLineupCard(
      tasking,
      group,
      jobSettings,
      radioConfig,
      externalData,
      planItems
    );
    const blob = await composer.renderToBlob();
    zip = zip.file(
      `KNEEBOARD/${type}/IMAGES/${group.name}_LineupCard.png`,
      blob
    );
  }

  return zip;
};
