import { firestore } from "firebase-admin";
import {
  Firestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import _ from "lodash";
import { GroupWithPlan } from "../../contexts/PlanManagerContext";
import { docs } from "../db_util";
import { convertDictionaryToJS, getMission } from "../lua";
import { FragOrderDoc } from "../models/FragOrder";
import {
  ControlMeasureEditor,
  PlanningRule,
  PublishManifestDoc,
  PublishOpts,
  TaskingState,
  createPublishManifest,
  sanitizeTaskingState,
} from "../models/PublishManifest";
import { SignupRule } from "../models/SignupRecord";
import { getTaskingState } from "../tasking";
import { Coalition } from "../types";
import { AssetManager } from "./AssetManager";
import { BundleClient } from "./BundleClient";
import { MizStorer } from "./MizStorer";

export const PublishManifestCollection = "PublishManifests";

export interface PublishReader {
  get(manifestID: string): Promise<PublishManifestDoc>;
}

export interface PublishManager {
  getPublishStatus(fragOrderID: string): Promise<PublishManifestDoc[]>;

  upsertPublishManifest(
    userID: string,
    coalition: Coalition,
    fragOrder: FragOrderDoc,
    opts: PublishOpts
  ): Promise<void>;

  upsertPlanningRule(pm: PublishManifestDoc, rule: PlanningRule): Promise<void>;

  upsertControlMeasureEditors(
    pm: PublishManifestDoc,
    editors: ControlMeasureEditor[]
  ): Promise<void>;

  upsertSignupRules(pm: PublishManifestDoc, rules: SignupRule[]): Promise<void>;

  toggleSignupsEnabled(pm: PublishManifestDoc, enabled: boolean): Promise<void>;

  removePlanningRule(pm: PublishManifestDoc, discordID: string): Promise<void>;
}

class reader {
  db: Firestore;
  bc: BundleClient;

  async get(manifestID: string): Promise<PublishManifestDoc> {
    const docRef = doc(this.db, PublishManifestCollection, manifestID);
    const docSnap = await getDoc(docRef);

    const data = docSnap.data();

    if (!data) {
      throw new Error("Frag Order not found");
    }

    return { ...data, id: manifestID } as PublishManifestDoc;
  }
}

export class ServerPublishReader implements PublishReader {
  adminDB: firestore.Firestore;

  constructor(db: firestore.Firestore) {
    this.adminDB = db;
  }

  async get(manifestID: string): Promise<PublishManifestDoc> {
    const docRef = this.adminDB.doc(
      `${PublishManifestCollection}/${manifestID}`
    );
    const docSnap = await docRef.get();

    const data = docSnap.data();

    if (!data) {
      throw new Error("Frag Order not found");
    }

    return { ...data, id: manifestID } as PublishManifestDoc;
  }
}

class manager {
  db: Firestore;
  ms: MizStorer;
  am: AssetManager;
  bc: BundleClient;

  private async getTaskingState(
    fragOrder: FragOrderDoc,
    coalition: Coalition
  ): Promise<TaskingState> {
    const miz = await this.ms.download(fragOrder.id);

    const [mission, dictionary, warehouses] = await getMission(miz);
    // TODO: make an opt

    const ts = getTaskingState(
      coalition,
      mission,
      warehouses,
      convertDictionaryToJS(dictionary)
    );

    return ts;
  }

  async getPublishStatus(fragOrderID: string) {
    const q = query(
      collection(this.db, PublishManifestCollection),
      where("fragOrderID", "==", fragOrderID)
    );

    const querySnapshot = await getDocs(q);

    const d = await docs<PublishManifestDoc>(querySnapshot);

    return d;
  }

  async upsertPublishManifest(
    userID: string,
    coalition: Coalition,
    fragOrder: FragOrderDoc,
    opts: PublishOpts
  ) {
    const manifests = await this.getPublishStatus(fragOrder.id);

    const existing = _.find(manifests, { coalition });

    const ts = await this.getTaskingState(fragOrder, coalition);

    const sanitized = sanitizeTaskingState(fragOrder, opts, coalition, ts);

    // Note we don't pass in sanitized here
    const nextManifest = createPublishManifest(fragOrder, coalition, opts);

    if (!existing) {
      const t = new Date().getTime();
      // No manifests exist yet. Creating for the first time.

      nextManifest.planningRules = [];

      const ref = collection(this.db, PublishManifestCollection);

      const docRef = doc(ref);

      const url = await this.bc.upsert(docRef.id, sanitized);

      const bundleAddress = url.toString();

      await setDoc(docRef, {
        ...nextManifest,
        created_by_uid: userID,
        created_at_timestamp: t,
        updated_at_timestamp: t,
        version: 2,
        bundleAddress,
      });

      return;
    }

    const ref = doc(this.db, PublishManifestCollection, existing.id);

    const combined = {
      ...existing,
      ...nextManifest,
      updated_at_timestamp: new Date().getTime(),
    };

    if (existing.version === 2) {
      const url = await this.bc.upsert(existing.id, sanitized);
      combined.bundleAddress = url.toString();
    } else {
      combined.taskingData = JSON.stringify(sanitized);
    }

    const p = setDoc(ref, combined);

    await this.am.updateGeneratedAssets(
      userID,
      existing.id,
      fragOrder.jobs,
      ts.plannedGroups as GroupWithPlan[],
      fragOrder.jobSettings
    );

    return p;
  }

  // @ts-ignore
  private getConflictingRules(
    pm: PublishManifestDoc,
    planningRule: PlanningRule
  ) {
    const conflictingRules = _.filter(pm.planningRules, (r) => {
      if (r.discordUserID === planningRule.discordUserID) {
        return false;
      }
      const newGroups = planningRule.plannableGroupNames;
      const intersection = _.intersection(newGroups, r.plannableGroupNames);
      return intersection.length > 0;
    });

    if (conflictingRules.length > 0) {
      const users = _.uniq(conflictingRules.map((r) => r.discordDisplayText));
      const groups = _.uniq(
        _.flatten(conflictingRules.map((r) => r.plannableGroupNames))
      );

      return { users, groups };
    }

    return null;
  }

  async upsertPlanningRule(pm: PublishManifestDoc, planningRule: PlanningRule) {
    // const conflicts = this.getConflictingRules(pm, planningRule);
    // if (conflicts) {
    //   throw new Error("Conflicting planning rules");
    // }

    const existingRule = _.find(pm.planningRules, {
      discordUserID: planningRule.discordUserID,
    });

    if (existingRule) {
      const next = pm.planningRules.map((r) => {
        if (r.discordUserID === planningRule.discordUserID) {
          return planningRule;
        }
        return r;
      });

      return setDoc(doc(this.db, PublishManifestCollection, pm.id), {
        ...pm,
        planningRules: next,
        updated_at_timestamp: new Date().getTime(),
      });
    }

    const next: PublishManifestDoc = {
      ...pm,
      planningRules: [...pm.planningRules, planningRule],
      updated_at_timestamp: new Date().getTime(),
    };
    if (!pm.id) {
      throw new Error(`Invalid manifest id: ${pm.id}`);
    }
    return setDoc(doc(this.db, PublishManifestCollection, pm.id), next);
  }

  removePlanningRule(pm: PublishManifestDoc, discordID: string) {
    const next = _.filter(
      pm.planningRules,
      (r) => r.discordUserID !== discordID
    );
    return setDoc(doc(this.db, PublishManifestCollection, pm.id), {
      ...pm,
      planningRules: next,
      updated_at_timestamp: new Date().getTime(),
    });
  }

  async upsertControlMeasureEditors(
    pm: PublishManifestDoc,
    editors: ControlMeasureEditor[]
  ) {
    let nextEditors;

    if (editors.length === 0) {
      nextEditors = [];
    } else {
      nextEditors = _.uniqBy(editors, "discordUserID");

      nextEditors = _.filter(nextEditors, (e) => {
        return e.discordUserID !== "" || e.displayName !== "";
      });
    }

    const next: PublishManifestDoc = {
      ...pm,
      controlMeasureEditors: nextEditors,
      updated_at_timestamp: new Date().getTime(),
    };

    if (!pm.id) {
      throw new Error(`Invalid manifest id: ${pm.id}`);
    }
    return setDoc(doc(this.db, PublishManifestCollection, pm.id), next);
  }

  async upsertSignupRules(pm: PublishManifestDoc, rules: SignupRule[]) {
    if (!pm.id) {
      throw new Error(`Invalid manifest id: ${pm.id}`);
    }

    const next = [];

    for (const r of rules) {
      const existing = _.find(pm.signupRules, { missionRole: r.missionRole });
      if (existing) {
        next.push({
          ...existing,
          ...r,
        });
      } else {
        next.push(r);
      }
    }

    return setDoc(doc(this.db, PublishManifestCollection, pm.id), {
      ...pm,
      signupRules: next,
      updated_at_timestamp: new Date().getTime(),
    });
  }

  async toggleSignupsEnabled(pm: PublishManifestDoc, enabled: boolean) {
    if (!pm.id) {
      throw new Error(`Invalid manifest id: ${pm.id}`);
    }
    return setDoc(doc(this.db, PublishManifestCollection, pm.id), {
      ...pm,
      signupsEnabled: enabled,
      updated_at_timestamp: new Date().getTime(),
    });
  }
}

export function NewPublishReader(
  db: Firestore,
  bc: BundleClient
): PublishReader {
  const r = new reader();
  r.db = db;
  r.bc = bc;
  return r;
}

export function NewPublishManager(
  db: Firestore,
  ms: MizStorer,
  am: AssetManager,
  bc: BundleClient
): PublishManager {
  const m = new manager();
  m.db = db;
  m.ms = ms;
  m.am = am;
  m.bc = bc;
  return m;
}
