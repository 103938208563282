import { APIUser } from "discord-api-types/v10";
import _ from "lodash";
import * as React from "react";
import { UseQueryOptions, useQuery, useQueryClient } from "react-query";
import { modules } from "../lib/data/modules";
import { Theater, convertLatLongToDCS } from "../lib/map";
import {
  LaserConfig,
  PlanDoc,
  PlanStatus,
  RadioChannelOverride,
  combinePlans,
} from "../lib/models/Plan";
import { PublishManifestDoc } from "../lib/models/PublishManifest";
import { PlanManager } from "../lib/services/PlanManager";

import { PlanningController } from "../lib/services/PlanningController";
import {
  AltitudeType,
  DCSGroupData,
  DCSWaypoint,
  WaypointAction,
  WaypointType,
} from "../lib/types";
import {
  useGetCurrentManifest,
  useGetCurrentTaskingData,
} from "./PublishReaderContext";
import { useNavPointController, useTaskingState } from "./TaskingMapContext";
import { useUser } from "./UserContext";

type PlanManagerContextType = PlanManager;

type Props = {
  children?: any;
  manager: PlanManager;
};

export const PlanManagerContext = React.createContext<PlanManagerContextType>(
  null
);

export function PlanManagerProvider({ manager, children }: Props) {
  return (
    <PlanManagerContext.Provider value={manager}>
      {children}
    </PlanManagerContext.Provider>
  );
}

export function usePlanManager() {
  return React.useContext(PlanManagerContext);
}

export const PlanQueryKey = "Plans";

export function useGetPlans(fragOrderID: string, opts?: UseQueryOptions) {
  const mgr = usePlanManager();
  return useQuery<PlanDoc[]>(
    [PlanQueryKey, fragOrderID],
    () => mgr.listPlans(fragOrderID),
    opts as any
  );
}

export type GroupWithPlan = DCSGroupData & { plan: PlanDoc };

export function mergePlansWithGroups(
  plans: PlanDoc[],
  groups: DCSGroupData[],
  filters?: PlanFilters
): GroupWithPlan[] {
  const filtered = _.filter(plans, filters) as PlanDoc[];
  const combined = combinePlans(filtered);

  return _.map(groups, (g) => {
    const f = { groupName: g.name } as any;

    if (filters?.status) {
      f.status = filters.status;
    }

    if (filters?.user) {
      f.created_by_uid = filters.user.id;
    }

    const plan = _.find(combined, f);

    const out = { ...g } as GroupWithPlan;

    if (plan) {
      if (plan.waypoints && plan.waypoints.length > 1) {
        out.waypoints = _.sortBy(plan.waypoints, "number");
      }

      if (plan.navPoints && plan.navPoints.length > 0) {
        out.navTargetPoints = _.sortBy(plan.navPoints, "index");
      }

      out.plan = plan;
    }

    return out;
  });
}

interface PlanFilters {
  status?: PlanStatus;
  user?: APIUser;
}

export const GroupsWithPlanQueryKey = "groups_with_plan";

export function useGroupsWithPlans(
  fragOrderID: string,
  groups: DCSGroupData[],
  filters?: PlanFilters
) {
  const mgr = usePlanManager();

  return useQuery<GroupWithPlan[]>(
    [GroupsWithPlanQueryKey, fragOrderID, groups],
    async () => {
      const plans = await mgr.listPlans(fragOrderID);
      return mergePlansWithGroups(plans, groups, filters);
    },
    { refetchInterval: Infinity }
  );
}

function plannedWaypointsForGroup(
  g: DCSGroupData,
  controller: PlanningController
) {
  const s = controller.findSource(g.name);

  if (!s) {
    return null;
  }
  return s.getFeatures();
}

export function useSubmitPlan(
  manifest: PublishManifestDoc,
  theater: Theater,
  controller: PlanningController
) {
  const mgr = usePlanManager();
  const { currentUser } = useUser();
  const queryClient = useQueryClient();
  const nav = useNavPointController();

  return async (
    g: DCSGroupData,
    radioOverrides: RadioChannelOverride[] = null,
    plannerNotes: string | null = null,
    laserConfig: LaserConfig | null = null
  ) => {
    let waypoints = g.waypoints;
    let navPoints = g.navTargetPoints;

    if (controller) {
      // I guess we were assuming that a planning controller was already initialized.
      // Found during laser codes, but was broken for notes as well.
      const features = plannedWaypointsForGroup(g, controller);
      const w: DCSWaypoint[] = _.map(
        features,
        convertFeatureToDCSWaypoint(theater)
      );
      waypoints = w;
    }

    if (nav) {
      const navPointFeatures = nav.getPlannedNavPoints(g.name);

      navPoints = _.map(g.navTargetPoints, (p) => {
        const match = _.find(navPointFeatures, (f) => {
          return f.get("point").index === p.index;
        });

        if (match) {
          const [long, lat] = match.getGeometry().getCoordinates();
          const { x, y } = convertLatLongToDCS(theater, { long, lat });
          const point = match.get("point");

          return {
            ...p,
            x,
            y,
            textComment: point.textComment,
            index: point.index,
          };
        }

        return p;
      });

      const newNavPoints = _.filter(navPointFeatures, (f) => {
        return !_.find(g.navTargetPoints, (p) => {
          return p.index === f.get("point").index;
        });
      });

      const formatted = _.map(newNavPoints, (f) => {
        const [long, lat] = f.getGeometry().getCoordinates();
        const { x, y } = convertLatLongToDCS(theater, { long, lat });
        const point = f.get("point");
        return {
          x,
          y,
          textComment: point.textComment,
          index: point.index,
        };
      });

      navPoints = _.concat(navPoints, formatted);
    }

    const moduleName = getModuleNameForGroup(g);

    const p = await mgr.upsertPlan(
      {
        user: currentUser,
        coalition: manifest.coalition,
        fragOrderID: manifest.fragOrderID,
        groupName: g.name,
        category: g.category,
        moduleName,
      },
      waypoints,
      radioOverrides,
      plannerNotes,
      laserConfig,
      navPoints
    );

    await Promise.all([
      queryClient.invalidateQueries([PlanQueryKey, manifest.fragOrderID]),
      queryClient.invalidateQueries([GroupsWithPlanQueryKey]),
    ]);

    return p;
  };
}

const convertFeatureToDCSWaypoint = (theater) => (f): DCSWaypoint => {
  const [long, lat] = f.getGeometry().getCoordinates();
  const { x, y } = convertLatLongToDCS(theater, { long, lat });
  const number = f.get("number");
  const name = f.get("name");

  return {
    name: name === number.toString() ? number.toString() : name,
    number,
    action: WaypointAction.TurningPoint,
    type: WaypointType.TurningPoint,
    alt: f.get("altitude") || 0,
    alt_type: f.get("altitudeType") || AltitudeType.AGL,
    x,
    y,
    ETA: f.get("eta") || 0,
  } as DCSWaypoint;
};

function getModuleNameForGroup(g: DCSGroupData): keyof typeof modules {
  return _.get(g.units, [0, "type"]) as keyof typeof modules;
}

export function useCopyWaypoints() {
  const {
    controller,
    state: {
      tasking: { plannedGroups },
    },
  } = useTaskingState();

  return (sourceGroup: string, targetGroup: string) => {
    return controller.current.copyWaypoints(
      sourceGroup,
      targetGroup,
      plannedGroups
    );
  };
}

export function useListMergedGroups() {
  const { data: manifest } = useGetCurrentManifest();
  const { data: tasking } = useGetCurrentTaskingData();

  return useGroupsWithPlans(manifest.fragOrderID, tasking.plannedGroups);
}
