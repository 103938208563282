import JSZip from "jszip";
import { renderCommsCard } from "../../components/Kneeboards/kneeboard_utils";
import { mergePlansWithGroups } from "../../contexts/PlanManagerContext";
import { MizJobFunc } from "../models/Job";
import { PlanDoc, PlanStatus } from "../models/Plan";
import { TaskingState } from "../models/PublishManifest";

export const injectCommsCard = (
  tasking: TaskingState,
  plans: PlanDoc[]
): MizJobFunc => async (zip: JSZip) => {
  const groups = mergePlansWithGroups(plans, tasking.plannedGroups, {
    status: PlanStatus.Submitted,
  });

  for (const group of groups) {
    const type = group.units[0].type;
    const composer = await renderCommsCard(group);
    const blob = await composer.renderToBlob();
    zip = zip.file(
      `KNEEBOARD/${type}/IMAGES/${group.name}_CommsCard.png`,
      blob
    );
  }

  return zip;
};
