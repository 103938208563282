import { Alert, Box } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import { useAdminGetCurrentFragOrder } from "../../hooks/frag_orders";
import PlanningManagement from "./PlanningManagement";
import { PlanningManagementProvider } from "./PlanningManagementContext";
import PlanningStatus from "./PlanningStatus";

function Wrapper(props) {
  const { data: fragOrder, isLoading, error } = useAdminGetCurrentFragOrder();

  if (isLoading) {
    return null;
  }

  if (error) {
    console.error(error);
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <PlanningManagementProvider>
      <Box marginBottom={2}>
        <PlanningStatus fragOrder={fragOrder} />
      </Box>
      <Box marginBottom={2}>
        <PlanningManagement {...props} fragOrder={fragOrder} />
      </Box>
    </PlanningManagementProvider>
  );
}

export default styled(Wrapper).attrs({ className: Wrapper.name })``;
