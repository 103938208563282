import _ from "lodash";
import { Feature, Map } from "ol";
import { touchOnly } from "ol/events/condition";
import { Geometry, Point } from "ol/geom";
import { DragPan } from "ol/interaction";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {
  FragOrderMapFeature,
  NewMapFeature,
} from "../models/FragOrderMapFeature";
import { FeatureClass } from "../types";
import { eventIsRightClick } from "./interactions/helpers";

export default function createMap(ref: HTMLDivElement) {
  // @ts-ignore
  ref.addEventListener("contextmenu", (event) => event.preventDefault());

  const map: any = new Map({
    target: ref,
  });

  const interactions = map.getInteractions();
  const defaultDragPans = _.filter(
    interactions.getArray(),
    (i) => i instanceof DragPan
  );
  _.each(defaultDragPans, (d) => map.removeInteraction(d));

  map.addInteraction(
    new DragPan({
      condition: function (event) {
        if (touchOnly(event)) {
          return true;
        }

        return eventIsRightClick(event);
      },
    })
  );

  return map;
}

export function getLayerByName(
  map,
  layerName: string
): VectorLayer<VectorSource<Geometry>> {
  const layers = map.getLayers();

  const layer = _.find(layers.getArray(), (l) => {
    if (!l) {
      return;
    }
    const name = l.get("name");

    return name === layerName;
  });

  return layer;
}

export function getFeatureByName(
  map,
  layerName: string,
  name: string | number,
  featureClass: FeatureClass
): FragOrderMapFeature {
  if (!map) {
    return null;
  }

  let layer = getLayerByName(map, layerName);

  if (!layer) {
    return null;
  }

  const source = layer.getSource();

  if (!source || !source.getFeatures) {
    return null;
  }

  const features = source.getFeatures();
  let feature = _.find(features, (f) => f.get("name") === name);

  if (featureClass === FeatureClass.Waypoint) {
    feature = _.find(features, (f) => f.get("number") === name);
  }

  if (!feature) {
    return null;
  }

  return NewMapFeature(feature as Feature<Point>);
}
