import { FirebaseMetadata } from "../types";

export enum PlanItemType {
  FuelPlan = "FuelPlan",
}

export type FuelPlan = {
  groundSpeed: number;
  fuelFlow: number;
  bingo: number;
  externalTanks: number;
  targetWaypoint: number;
};

export interface PlanItem {
  group: string;
  type: PlanItemType;
  manifestID: string;
  payload: string;
}

export type PlanItemDoc = PlanItem & FirebaseMetadata;
