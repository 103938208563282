import { Box, Button, TextField } from "@mui/material";
import * as React from "react";
import styled from "styled-components";
import {
  formatLatLong2,
  LatLongDecimal,
  mgrsToLatLong,
  parseLatLong,
} from "../lib/map";
import WaypointFeature from "../lib/models/WaypointFeature";
import Flex from "./Flex";

type Props = {
  className?: string;
  initialValue?: WaypointFeature;
  onSubmit: (coords: LatLongDecimal) => void;
};

function CoordinatesInput({ className, initialValue, onSubmit }: Props) {
  const [errors, setErrors] = React.useState<{ mgrs: string | null }>({
    mgrs: null,
  });
  const coords = initialValue?.coordinates();

  let initialState;

  const { lat, long, mgrs } = formatLatLong2([coords[0], coords[1]]);

  if (coords) {
    initialState = {
      latitude: lat,
      longitude: long,
      mgrs,
    };
  } else {
    initialState = {
      latitude: "",
      longitude: "",
      mgrs: "",
    };
  }
  const [state, setState] = React.useState(initialState);

  const handleChange = (field: string, value: string) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  const handleBlur = React.useCallback(
    (field: "latitude" | "longitude" | "mgrs") => {
      if (field === "mgrs") {
        try {
          const { long, lat } = mgrsToLatLong(state.mgrs);

          const { lat: latF, long: longF, mgrs: mgrsF } = formatLatLong2([
            long,
            lat,
          ]);
          setState({
            ...state,
            latitude: latF,
            longitude: longF,
            mgrs: mgrsF,
          });
        } catch (e) {
          setErrors({ mgrs: "Invalid MGRS" });
        }

        setErrors({ mgrs: null });
        return;
      }

      const { latitude, longitude } = parseLatLong(
        `${state.latitude} ${state.longitude}`
      );

      const { lat, long, mgrs } = formatLatLong2([longitude, latitude]);

      setState({
        ...state,
        latitude: lat,
        longitude: long,
        mgrs: mgrs,
      });
    },
    [state]
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { latitude, longitude } = parseLatLong(
      `${state.latitude} ${state.longitude}`
    );

    onSubmit({ lat: latitude, long: longitude });
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit}>
        <div>
          <Box mb={2}>
            <Flex align>
              <TextField
                onChange={(e) => handleChange("latitude", e.target.value)}
                label="Latitude"
                value={state.latitude}
                onBlur={() => handleBlur("latitude")}
              />
            </Flex>
          </Box>
          <Box mb={2}>
            <TextField
              onChange={(e) => handleChange("longitude", e.target.value)}
              label="Longitude"
              value={state.longitude}
              onBlur={() => handleBlur("longitude")}
            />
          </Box>
        </div>
        <Box mb={2}>
          <TextField
            onChange={(e) => handleChange("mgrs", e.target.value)}
            label="MGRS"
            value={state.mgrs}
            onBlur={() => handleBlur("mgrs")}
            error={!!errors.mgrs}
            helperText={errors.mgrs}
          />
        </Box>
        <Box>
          <Flex align>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </Flex>
        </Box>
      </form>
    </div>
  );
}

export default styled(CoordinatesInput).attrs({
  className: CoordinatesInput.name,
})``;
